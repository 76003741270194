import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { getCalendarReports } from "store/slices/reportSlice";
import { CirclesWithBar } from "react-loader-spinner";

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const { calenderEvents } = useSelector((state) => state.report);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getReports = () => {
    dispatch(getCalendarReports({ setLoading }));
  };
  useEffect(() => {
    getReports();
  }, []);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <CirclesWithBar
          height="100"
          width="100"
          color="#0D4cb5"
          outerCircleColor="#0D4cb5"
          innerCircleColor="#0D4cb5"
          barColor="#0D4cb5"
          ariaLabel="circles-with-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="container my-5">
      <h2 className="text-center mb-5">التقويم</h2>
      <Calendar
        localizer={localizer}
        events={calenderEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div>
  );
};

export default MyCalendar;
