import React from "react";
import { Container } from "react-bootstrap";

function About() {
  return (
    <Container className="mt-5" dir="rtl">
      <h2 className="mb-4 text-center">عن الموقع</h2>
      <p>هذه صفحة توضح تفاصيل الموقع والخدمات المقدمة.</p>
    </Container>
  );
}

export default About;
