import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addReport } from "store/slices/reportSlice";
import { CirclesWithBar } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  caseStatusOptions,
  caseTypeOptions,
  courtOptions,
} from "constants/enums";
import swal from "sweetalert";
import DynamicForm from "./DynamicForm";

const initialFormData = {
  case_number: "",
  case_type: "جنائية",
  court: "محكمة أبوظبي",
  client_name: "",
  client_email: "",
  client_username: "",
  client_password: "",
  case_status: "قيد التحقيق", // Default status
  case_reason: "",
  notes: "",
  case_amount: "",
  remaining_case_amount: "",
  remaining_injazat_amount: "",
};

function CaseForm() {
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [date1, setDate1] = useState("");
  const [oldCourtDates, setOldCourtDates] = useState([
    { date: "", description: "" },
  ]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateDates = () => {
    if (!date1) {
      swal("Error", "التاريخ الأول مطلوب.", "error");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateDates()) {
      let body = {
        ...formData,
        oldCourtDates,
        dates: [{ date: date1 }],
      };
      dispatch(
        addReport({
          body,
          setLoading,
          setFormData,
          initialFormData,
          navigate,
        })
      );
    }
  };
  const handleListChange = (list) => {
    setOldCourtDates(list);
  };

  return (
    <>
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <CirclesWithBar
            height="100"
            width="100"
            color="#0D4cb5"
            outerCircleColor="#0D4cb5"
            innerCircleColor="#0D4cb5"
            barColor="#0D4cb5"
            ariaLabel="circles-with-bar-loading"
            visible={true}
          />
        </div>
      )}
      <Container className="mt-5" dir="rtl">
        <Card className="shadow-sm">
          <Card.Body>
            <h2 className="mb-4 text-center">إضافة قضية جديدة</h2>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="caseNumber" className="mb-4">
                    <Form.Label>رقم القضية</Form.Label>
                    <Form.Control
                      type="text"
                      name="case_number"
                      value={formData.case_number}
                      onChange={handleInputChange}
                      placeholder="أدخل رقم القضية"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="caseType" className="mb-4">
                    <Form.Label>نوع القضية</Form.Label>
                    <Form.Select
                      name="case_type"
                      value={formData.case_type}
                      onChange={handleInputChange}
                      required
                    >
                      {Object.entries(caseTypeOptions).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="court" className="mb-4">
                    <Form.Label>المحكمة</Form.Label>
                    <Form.Select
                      name="court"
                      value={formData.court}
                      onChange={handleInputChange}
                      required
                    >
                      {Object.entries(courtOptions).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="clientName" className="mb-4">
                    <Form.Label>اسم العميل</Form.Label>
                    <Form.Control
                      type="text"
                      name="client_name"
                      value={formData.client_name}
                      onChange={handleInputChange}
                      placeholder="أدخل اسم العميل"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="caseStatus" className="mb-4">
                    <Form.Label>حالة القضية</Form.Label>
                    <Form.Select
                      name="case_status"
                      value={formData.case_status}
                      onChange={handleInputChange}
                      required
                    >
                      {Object.entries(caseStatusOptions).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="caseReason" className="mb-4">
                    <Form.Label>سبب القضية</Form.Label>
                    <Form.Control
                      type="text"
                      name="case_reason"
                      value={formData.case_reason}
                      onChange={handleInputChange}
                      placeholder="أدخل سبب القضية"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="client_email" className="mb-4">
                    <Form.Label>بريد الكتروني للعميل</Form.Label>
                    <Form.Control
                      type="email"
                      name="client_email"
                      value={formData.client_email}
                      onChange={handleInputChange}
                      placeholder="أدخل البريد الإلكتروني"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="client_username" className="mb-4">
                    <Form.Label>اسم مستخدم العميل</Form.Label>
                    <Form.Control
                      name="client_username"
                      value={formData.client_username}
                      onChange={handleInputChange}
                      placeholder="أدخل اسم المستخدم"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="client_password" className="mb-4">
                    <Form.Label>كلمة مرور العميل</Form.Label>
                    <Form.Control
                      name="client_password"
                      value={formData.client_password}
                      onChange={handleInputChange}
                      placeholder="أدخل كلمة المرور"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="caseAmount" className="mb-4">
                    <Form.Label>مبلغ المطالبة</Form.Label>
                    <Form.Control
                      type="number"
                      name="case_amount"
                      value={formData.case_amount}
                      onChange={handleInputChange}
                      placeholder="أدخل مبلغ المطالبة"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="remainingCaseAmount" className="mb-4">
                    <Form.Label>المبلغ المتبقي للقضية</Form.Label>
                    <Form.Control
                      type="number"
                      name="remaining_case_amount"
                      value={formData.remaining_case_amount}
                      onChange={handleInputChange}
                      placeholder="أدخل المبلغ المتبقي للقضية"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="remainingInjazatAmount"
                    className="mb-4"
                  >
                    <Form.Label>المبلغ المتبقي لإنجازات</Form.Label>
                    <Form.Control
                      type="number"
                      name="remaining_injazat_amount"
                      value={formData.remaining_injazat_amount}
                      onChange={handleInputChange}
                      placeholder="أدخل المبلغ المتبقي لإنجازات"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="date1" className="mb-4">
                    <Form.Label>التاريخ</Form.Label>
                    <Form.Control
                      type="date"
                      name="date1"
                      value={date1}
                      onChange={(e) => setDate1(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="notes" className="mb-4">
                <Form.Label>الملاحظات</Form.Label>
                <Form.Control
                  as="textarea"
                  name="notes"
                  value={formData.notes}
                  onChange={handleInputChange}
                  rows={3}
                  placeholder="أدخل ملاحظات إضافية"
                />
              </Form.Group>
              <h4>مواعيد المحكمة القديمة</h4>
              <div className="mb-5 mt-3">
                <DynamicForm
                  handleListChange={handleListChange}
                  defaultValue={oldCourtDates}
                />
              </div>
              <Button variant="primary" type="submit" className="w-100">
                إضافة القضية
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default CaseForm;
