import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post, put, get, del } from "config/http";
import swal from "sweetalert";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
const endPoint = process.env.REACT_APP_SERVER_ROOT_URL;

const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error("Invalid token:", error);
    return true;
  }
};

let initialState = {
  user: {},
  isAuthenticated: false,
  allUsers: [],
};

if (typeof window !== "undefined") {
  const user = localStorage?.getItem("user");
  const isAuthenticated = localStorage?.getItem("isAuthenticated");
  const token = localStorage?.getItem("jwtoken");

  if (user && isAuthenticated) {
    if (token && isTokenExpired(token)) {
      initialState.user = {};
      initialState.isAuthenticated = false;
    } else {
      const decodedUser = jwtDecode(token);
      let parsedUser = JSON.parse(user);
      initialState.user = { ...parsedUser, role: decodedUser.role };
      initialState.isAuthenticated = true;
    }
  }
}

export const doRegister = createAsyncThunk(
  "authSlice/doRegister",
  async ({ body, setLoading, router, setError, dispatch, selectedImage }) => {
    try {
      setLoading(true);
      const { data } = await post(`${endPoint}/api/v1/auth/register`, body);
      swal("نجاح!", data.msg, "success");
      router.push(`/auth/verifyOTP/${data?.data?.email}`);
      return data;
    } catch (error) {
      console.error(error);
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401 || status == 500) {
        msg = data?.message;
        setError(msg);
      }
      // swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const doLogin = createAsyncThunk(
  "authSlice/doLogin",
  async ({ body, setLoading, navigate }) => {
    try {
      setLoading(true);
      const { data } = await post(`${endPoint}/api/v1/user/login`, body);
      localStorage?.setItem("user", JSON.stringify(data?.data));
      localStorage?.setItem("isAuthenticated", JSON.stringify(true));
      localStorage?.setItem("jwtoken", data?.jwtoken);
      swal("نجاح!", data.msg, "success");
      axios.defaults.headers.common["jwtoken"] = data?.jwtoken;
      navigate("/dashboard");
      console.log(data?.data);

      return data?.data;
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (
        status === 400 ||
        status === 401 ||
        status === 500 ||
        status === 404 ||
        status === 403
      ) {
        msg = data.message;
        console.log(msg);
      }

      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const getUser = createAsyncThunk(
  "authSlice/getUser",
  async ({ setLoading, setUserData }) => {
    try {
      setLoading(true);
      const { data } = await get(`${endPoint}/api/v1/user/getUser`);
      setUserData(data?.data);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401 || status == 500 || status == 404) {
        msg = data?.message;
      }
      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const updateUser = createAsyncThunk(
  "authSlice/updateUser",
  async ({ body, setLoading }) => {
    try {
      setLoading(true);
      const { data } = await put(`${endPoint}/api/v1/user/updateUser`, body);
      swal("نجاح!", data.msg, "success");
      return data.data;
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401 || status == 500 || status == 404) {
        msg = data?.message;
      }
      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const editUserByAdmin = createAsyncThunk(
  "authSlice/editUserByAdmin",
  async ({ body, setLoading, handleClose }) => {
    try {
      setLoading(true);
      const { data } = await put(
        `${endPoint}/api/v1/user/updateUserByAdmin`,
        body
      );
      swal("نجاح!", data.msg, "success");
      handleClose();
      return data.data;
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401 || status == 500 || status == 404) {
        msg = data?.message;
      }
      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const addUser = createAsyncThunk(
  "authSlice/addUser",
  async ({ body, setLoading, handleClose }) => {
    try {
      setLoading(true);
      const { data } = await post(`${endPoint}/api/v1/user/addUser`, body);
      swal("نجاح!", data.msg, "success");
      handleClose();
      return data.data;
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401 || status == 500 || status == 404) {
        msg = data?.message;
      }
      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);
export const deleteUser = createAsyncThunk(
  "authSlice/deleteUser",
  async ({ id, setLoading }) => {
    try {
      setLoading(true);
      const { data } = await del(`${endPoint}/api/v1/user/deleteUser?id=${id}`);
      swal("نجاح!", data.msg, "success");
      return id;
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401 || status == 500 || status == 404) {
        msg = data?.message;
      }
      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "authSlice/getAllUsers",
  async ({ page, setLoading, setCount, role, search }) => {
    try {
      setLoading(true);
      const { data } = await get(
        `${endPoint}/api/v1/user/getAll?page=${page}&limit=50&search=${search}&role=${role}`
      );
      setCount(data?.count);
      return data.data;
    } catch (error) {
      console.error(error.response);
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401 || status == 500) {
        msg = data?.message;
      }
      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const doLogOut = createAsyncThunk("authSlice/doLogOut", async () => {
  try {
    localStorage?.removeItem("user");
    localStorage?.removeItem("isAuthenticated");
    localStorage?.removeItem("jwtoken");
    swal("نجاح!", "تم تسجيل الخروج بنجاح!", "success");
  } catch (error) {
    swal("خطأ!", error.message, "error");
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = action.payload ? true : false;
    });
    builder.addCase(doLogOut.fulfilled, (state, action) => {
      state.user = {};
      state.isAuthenticated = false;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      let data = {
        email: action.payload?.email,
        fullName: action.payload?.fullName,
        userID: action.payload?.userID,
        role: action.payload?.role,
        id: action.payload?._id,
      };
      state.user = data;
      localStorage?.setItem("user", JSON.stringify(data));
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.allUsers = state.allUsers.filter(
        (item) => item._id !== action.payload
      );
    });
  },
});

export default userSlice.reducer;
