/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "../styles/CustomFormStyles.css";
import { useDispatch } from "react-redux";
import { getReportWithId } from "store/slices/reportSlice";
import { CirclesWithBar } from "react-loader-spinner";
import {
  caseStatusOptions,
  caseTypeOptions,
  courtOptions,
} from "constants/enums";
import moment from "moment";

function ViewCase() {
  const { id } = useParams();
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  console.log(reportData);

  const getReport = () => {
    dispatch(getReportWithId({ id, setLoading, setReportData }));
  };

  // Fetch case data on component mount
  useEffect(() => {
    if (id) {
      getReport();
    }
  }, [id]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <CirclesWithBar
          height="100"
          width="100"
          color="#0D4cb5"
          outerCircleColor="#0D4cb5"
          innerCircleColor="#0D4cb5"
          barColor="#0D4cb5"
          ariaLabel="circles-with-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <Container className="my-5" dir="rtl">
      <Card className="shadow-lg border-0 p-5 ">
        <h2 className="text-center mb-5">عرض تفاصيل القضية</h2>
        <Row className="text-end">
          <Col md={6}>
            <div className="mb-3">
              <strong>رقم القضية: </strong>
              <span>{reportData?.case_number}</span>
            </div>
            <div className="mb-3">
              <strong>سبب القضية: </strong>
              <span>{reportData?.case_reason}</span>
            </div>
            <div className="mb-3">
              <strong>حالة القضية: </strong>
              <span>{caseStatusOptions[reportData?.case_status]}</span>
            </div>
            <div className="mb-3">
              <strong>نوع الحالة: </strong>
              <span>{caseTypeOptions[reportData?.case_type]}</span>
            </div>
            <div className="mb-3">
              <strong>تاريخ: </strong>
              <span>
                {reportData?.dates?.map((item, i) => {
                  return (
                    <span key={i}>
                      {moment(item.date).format("DD/MM/YYYY h:mm A")}
                    </span>
                  );
                })}
              </span>
            </div>
            <div className="mb-3">
              <strong>تاريخ الإنشاء: </strong>
              <span>
                {moment(reportData?.createdAt).format("DD/MM/YYYY h:mm A")}
              </span>
            </div>
            <div className="mb-3">
              <strong>تمت الإضافة بواسطة: </strong>
              <span>{reportData?.addedBy?.email}</span>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <strong>اسم العميل: </strong>
              <span>{reportData?.client_name}</span>
            </div>
            {reportData?.client_email && (
              <div className="mb-3">
                <strong>بريد الكتروني للعميل: </strong>
                <span>{reportData?.client_email}</span>
              </div>
            )}
            {reportData?.client_username && (
              <div className="mb-3">
                <strong>اسم مستخدم العميل: </strong>
                <span>{reportData?.client_username}</span>
              </div>
            )}
            {reportData?.client_password && (
              <div className="mb-3">
                <strong>كلمة مرور العميل: </strong>
                <span>{reportData?.client_password}</span>
              </div>
            )}
            <div className="mb-3">
              <strong>محكمة: </strong>
              <span>{courtOptions[reportData?.court]}</span>
            </div>
            <div className="mb-3">
              <strong>مبلغ القضية: </strong>
              <span>{reportData?.case_amount}</span>
            </div>
            <div className="mb-3">
              <strong>المبلغ المتبقي من القضية: </strong>
              <span>{reportData?.remaining_case_amount}</span>
            </div>
            <div className="mb-3">
              <strong>المبلغ المتبقي من الانجازات: </strong>
              <span>{reportData?.remaining_injazat_amount}</span>
            </div>
          </Col>
        </Row>
        <hr />
        <div className="mb-3 text-end">
          <div className="mb-4">
            <strong>مواعيد المحكمة القديمة</strong>
          </div>
          {reportData?.oldCourtDates?.map((item, i) => {
            return (
              <div key={i}>
                {item?.date && (
                  <>
                    <span className="fw-bold">
                      {moment(item?.date).format("DD/MM/YYYY")}-{" "}
                    </span>
                    <span>{item.description}</span>
                  </>
                )}
              </div>
            );
          })}
        </div>
        <hr />
        <Row>
          <Col>
            <div className="mb-3">
              <strong>ملحوظات: </strong>
              <span>{reportData?.notes}</span>
            </div>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

export default ViewCase;
