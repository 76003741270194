import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import CaseForm from "./components/CaseForm";
import EditCase from "./components/EditCase";
import Profile from "./components/Profile";
import ProfileDetails from "./components/ProfileDetails";
import Login from "./components/Login";
import Signup from "./components/Signup";
import NavbarComponent from "./components/Navbar";
import CalendarPage from "./components/CalendarPage";
import About from "./components/About";
import HistoryCases from "./components/HistoryCases";
import Dashboard from "./components/Dashboard";
import Users from "./components/Users";
import "./App.css";
import { useSelector } from "react-redux";
import ActiveCases from "components/ActiveCases";
import ViewCase from "components/ViewCase";

function App() {
  const { isAuthenticated } = useSelector((state) => state.user);

  return (
    <Router>
      <NavbarComponent />
      <div className="App">
        <Routes>
          {/* Redirect the root path to login if not authenticated, else to dashboard */}
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />

          {/* Private Routes: These routes are only accessible when authenticated */}
          <Route
            path="/dashboard"
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
          />
          <Route
            path="/users"
            element={isAuthenticated ? <Users /> : <Navigate to="/login" />}
          />
          <Route
            path="/add"
            element={isAuthenticated ? <CaseForm /> : <Navigate to="/login" />}
          />
          <Route
            path="/edit/:id"
            element={isAuthenticated ? <EditCase /> : <Navigate to="/login" />}
          />
          <Route
            path="/view/:id"
            element={isAuthenticated ? <ViewCase /> : <Navigate to="/login" />}
          />
          <Route
            path="/profile"
            element={isAuthenticated ? <Profile /> : <Navigate to="/login" />}
          />
          <Route
            path="/profile/details"
            element={
              isAuthenticated ? <ProfileDetails /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/calendar"
            element={
              isAuthenticated ? <CalendarPage /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/activeCases"
            element={
              isAuthenticated ? <ActiveCases /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/history"
            element={
              isAuthenticated ? <HistoryCases /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/about"
            element={isAuthenticated ? <About /> : <Navigate to="/login" />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
