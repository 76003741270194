import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Pie, Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardStats,
  getGraphCasesByCourts,
  getGraphCasesByMonths,
  getGraphCasesByStatus,
} from "store/slices/reportSlice";
import { ThreeCircles } from "react-loader-spinner";

// تسجيل العناصر والمقاييس
ChartJS.register(
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

function Dashboard() {
  const {
    courtLabels,
    courtCounts,
    statusLabels,
    statusCounts,
    monthsLabels,
    dataByMonths,
    statuses,
  } = useSelector((state) => state.report);
  const [graphCaseLoading, setGraphCaseLoading] = useState(true);
  const [statusGraphLoading, setStatusGraphLoading] = useState(true);
  const [monthsGraphLoading, setMonthsGraphLoading] = useState(true);
  const [totalCases, setTotalCases] = useState(0);
  const [activeCases, setActiveCases] = useState(0);
  const [doneCases, setDoneCases] = useState(0);
  const dispatch = useDispatch();

  const getStats = () => {
    dispatch(dashboardStats({ setTotalCases, setActiveCases, setDoneCases }));
  };
  const getCourtCases = () => {
    dispatch(getGraphCasesByCourts({ setGraphCaseLoading }));
  };
  const getCasesStatus = () => {
    dispatch(getGraphCasesByStatus({ setStatusGraphLoading }));
  };
  const getCasesByMonths = () => {
    dispatch(getGraphCasesByMonths({ setMonthsGraphLoading }));
  };
  useEffect(() => {
    getStats();
    getCourtCases();
    getCasesStatus();
    getCasesByMonths();
  }, []);

  const pieData = {
    labels: statusLabels,
    datasets: [
      {
        data: statusCounts,
        backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
      },
    ],
  };

  const barData = {
    labels: courtLabels,
    datasets: [
      {
        label: "التقارير المضافة",
        data: courtCounts,
        backgroundColor: "#FFCE56",
      },
    ],
  };

  const lineDataByMonths = {
    labels: monthsLabels, // الأشهر على المحور الأفقي
    datasets: statuses.map((status, index) => ({
      label: status,
      data: monthsLabels.map((month) => {
        const monthData = dataByMonths.find(
          (item) => item.month === month && item.caseStatusLabel === status
        );
        return monthData ? monthData.count : 0; // عرض 0 إذا لم توجد بيانات لذلك الشهر
      }),
      borderColor: [`#36A2EB`, "#FF6384", "#FFCE56"],
      backgroundColor: [
        `rgba(54, 162, 235,0.2)`,
        `rgba(255, 99, 132,0.2)`,
        `rgba(255, 206, 86,0.2)`,
      ],
      fill: true,
    })),
  };

  const hasData = (dataArray) =>
    dataArray && dataArray.some((value) => value > 0);

  return (
    <Container className="mt-5">
      <h2 className="mb-4">لوحة التحكم</h2>
      <Row>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <h5>مجموع القضايا: {totalCases}</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <h5>القضايا قيد المتابعة: {activeCases}</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <h5>القضايا المنتهية: {doneCases}</h5>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h5>القضايا حسب الحالة</h5>
          {statusGraphLoading ? (
            <div className="d-flex justify-content-center align-items-center mt-3">
              <ThreeCircles
                height="50"
                width="50"
                color="#0D4cb5"
                outerCircleColor="#0D4cb5"
                innerCircleColor="#0D4cb5"
                barColor="#0D4cb5"
                ariaLabel="circles-with-bar-loading"
                visible={true}
              />
            </div>
          ) : hasData(statusCounts) ? (
            <Card className="border">
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div style={{ width: "400px", height: "400px" }}>
                  <Pie
                    data={pieData}
                    options={{ responsive: true, maintainAspectRatio: false }}
                  />
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Card>
              <Card.Body>
                <div className="text-center mt-3">
                  <p>لا توجد بيانات متاحة للقضايا حسب الحالة</p>
                </div>
              </Card.Body>
            </Card>
          )}
        </Col>
        <Col md={6} className="my-5 my-md-0">
          <h5>القضايا حسب المحكمة</h5>
          {graphCaseLoading ? (
            <div className="d-flex justify-content-center align-items-center mt-3">
              <ThreeCircles
                height="50"
                width="50"
                color="#0D4cb5"
                outerCircleColor="#0D4cb5"
                innerCircleColor="#0D4cb5"
                barColor="#0D4cb5"
                ariaLabel="circles-with-bar-loading"
                visible={true}
              />
            </div>
          ) : hasData(courtCounts) ? (
            <Card className="border">
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div style={{ width: "100%", height: "400px" }}>
                  <Bar
                    data={barData}
                    options={{ responsive: true, maintainAspectRatio: false }}
                  />
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Card>
              <Card.Body>
                <div className="text-center mt-3">
                  <p>لا توجد بيانات متاحة للقضايا حسب المحكمة</p>
                </div>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <Row className="my-5">
        <Col md={12}>
          <h5>الاتجاهات الشهرية للقضايا</h5>
          {monthsGraphLoading ? (
            <div className="d-flex justify-content-center align-items-center mt-3">
              <ThreeCircles
                height="50"
                width="50"
                color="#0D4cb5"
                outerCircleColor="#0D4cb5"
                innerCircleColor="#0D4cb5"
                barColor="#0D4cb5"
                ariaLabel="circles-with-bar-loading"
                visible={true}
              />
            </div>
          ) : hasData(dataByMonths.map((month) => month.count)) ? (
            <Card className="border">
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div style={{ width: "100%", height: "400px" }}>
                  <Line
                    data={lineDataByMonths}
                    options={{ responsive: true, maintainAspectRatio: false }}
                  />
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Card>
              <Card.Body>
                <div className="text-center mt-3">
                  <p>لا توجد بيانات متاحة للاتجاهات الشهرية للقضايا</p>
                </div>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
