export const caseStatusOptions = {
  under_investigation: "قيد التحقيق",
  court: "محكمة",
  reserved: "محفوظة",
};

export const caseTypeOptions = {
  criminal: "جنائية",
  civilian: "مدنية",
  commercial: "تجارية",
};

export const courtOptions = {
  abu_dhabi_court: "محكمة أبوظبي",
  dubai_court: "محكمة دبي",
  sharjah_court: "محكمة الشارقة",
  ajman_court: "محكمة عجمان",
  umm_al_quwain_court: "محكمة أم القيوين",
  ras_al_khaimah_court: "محكمة رأس الخيمة",
  fujairah_court: "محكمة الفجيرة",
};

export const ROLES = {
  user: "مستخدم",
  admin: "مسؤول",
  super_admin: "المشرف الفائق",
};
