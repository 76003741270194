import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { getUser, updateUser } from "store/slices/userSlice";
import swal from "sweetalert";

function Profile() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();

  const getCurrentUser = () => {
    dispatch(getUser({ setLoading, setUserData }));
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    setFormData(userData);
  }, [userData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Implement validation logic for passwords, if needed
    if (formData.password !== formData.confirmPassword) {
      swal("خطأ!", "كلمات المرور غير متطابقة", "error");
      return;
    }

    dispatch(updateUser({ body: formData, setLoading }));
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <CirclesWithBar
          height="100"
          width="100"
          color="#0D4cb5"
          outerCircleColor="#0D4cb5"
          innerCircleColor="#0D4cb5"
          barColor="#0D4cb5"
          ariaLabel="circles-with-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <Container className="my-5" dir="rtl">
      <Card className="shadow-sm">
        <Card.Body>
          <h2 className="mb-4 text-center">الملف الشخصي</h2>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="fullName" className="mb-4">
                  <Form.Label>الاسم الكامل</Form.Label>
                  <Form.Control
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    placeholder="أدخل اسمك الكامل"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="email" className="mb-4">
                  <Form.Label>البريد الإلكتروني</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    disabled
                    placeholder="أدخل بريدك الإلكتروني"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="phone" className="mb-4">
                  <Form.Label>رقم الهاتف</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="أدخل رقم هاتفك"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="password" className="mb-4">
                  <Form.Label>كلمة المرور الجديدة</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="أدخل كلمة المرور الجديدة"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="confirmPassword" className="mb-4">
                  <Form.Label>تأكيد كلمة المرور</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    placeholder="أدخل تأكيد كلمة المرور"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button variant="primary" type="submit" className="w-100">
              تحديث الملف الشخصي
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Profile;
