import React from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { doLogOut } from "store/slices/userSlice";
import { ROLES } from "constants/enums";

function NavbarComponent() {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(doLogOut());
    navigate("/login");
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" dir="rtl">
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <span className="small">متابعة القضايا</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto small">
            {isAuthenticated && (
              <>
                <Nav.Link as={NavLink} to="/activeCases">
                  قائمة القضايا
                </Nav.Link>
                <Nav.Link as={NavLink} to="/history">
                  أرشيف القضايا
                </Nav.Link>
                <Nav.Link as={NavLink} to="/profile">
                  الملف الشخصي
                </Nav.Link>
                {(ROLES[user.role] === ROLES.super_admin ||
                  ROLES[user.role] === ROLES.admin) && (
                  <Nav.Link as={NavLink} to="/users">
                    المستخدمين
                  </Nav.Link>
                )}
                <Nav.Link as={NavLink} to="/calendar">
                  تقويم الحالة
                </Nav.Link>
                <Nav.Link as={NavLink} to="/dashboard">
                  لوحة التحكم
                </Nav.Link>
              </>
            )}
          </Nav>
          <Nav>
            {isAuthenticated ? (
              <Button
                onClick={handleLogout}
                variant="outline-light"
                className="me-2"
              >
                تسجيل الخروج
              </Button>
            ) : (
              <>
                <Button
                  as={NavLink}
                  to="/login"
                  variant="outline-light"
                  className="me-2"
                >
                  تسجيل الدخول
                </Button>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
