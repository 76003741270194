import React from "react";
import ReactPaginate from "react-paginate";
import "styles/pagination.css";


const Pagination = ({ count, onPageChange }) => {
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel=">"
      onPageChange={onPageChange}
      pageRangeDisplayed={5}
      pageCount={count}
      previousLabel="<"
      renderOnZeroPageCount={null}
      containerClassName="pagination"
      activeClassName={'active'}
    />
  );
};

export default Pagination;
