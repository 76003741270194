import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del, get, post, put } from "config/http";
import swal from "sweetalert";
const endPoint = process.env.REACT_APP_SERVER_ROOT_URL;

export const addReport = createAsyncThunk(
  "reportSlice/addReport",
  async ({ body, setLoading, initialFormData, setFormData, navigate }) => {
    try {
      setLoading(true);
      const { data } = await post(`${endPoint}/api/v1/report/add`, body);
      swal("نجاح!", data.msg, "success");
      setFormData(initialFormData);
      navigate("/activeCases");
      return data?.data;
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (
        status === 400 ||
        status === 401 ||
        status === 500 ||
        status === 404 ||
        status === 403
      ) {
        msg = data.message;
        console.log(msg);
      }

      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const deleteReport = createAsyncThunk(
  "reportSlice/deleteReport",
  async ({ id, setLoading }) => {
    try {
      setLoading(true);
      const { data } = await del(`${endPoint}/api/v1/report/delete?id=${id}`);
      swal("نجاح!", data.msg, "success");
      return id;
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (
        status === 400 ||
        status === 401 ||
        status === 500 ||
        status === 404 ||
        status === 403
      ) {
        msg = data.message;
        console.log(msg);
      }

      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const getAllReports = createAsyncThunk(
  "reportSlice/getAllReports",
  async ({ page, setCount, search, setLoading, isActiveCases }) => {
    try {
      setLoading(true);
      const { data } = await get(
        `${endPoint}/api/v1/report/get_all_reporadd?page=${page}&limit=50&isActiveCases=${isActiveCases}&search=${search}`
      );
      setCount(data.count);
      return data?.data;
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (
        status === 400 ||
        status === 401 ||
        status === 500 ||
        status === 404 ||
        status === 403
      ) {
        msg = data.message;
        console.log(msg);
      }

      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const shareReport = createAsyncThunk(
  "reportSlice/shareReport",
  async ({ body, setLoading, handleClose }) => {
    try {
      setLoading(true);
      const { data } = await post(
        `${endPoint}/api/v1/report/shareReport`,
        body
      );
      swal("نجاح!", data.msg, "success");
      handleClose();
      return data.data;
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (
        status === 400 ||
        status === 401 ||
        status === 500 ||
        status === 404
      ) {
        msg = data?.message;
      }
      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const getReportWithId = createAsyncThunk(
  "reportSlice/getReportWithId",
  async ({ id, setLoading, setReportData }) => {
    try {
      setLoading(true);
      const { data } = await get(
        `${endPoint}/api/v1/report/getReportWithId/${id}`
      );
      setReportData(data?.data);
      return data?.data;
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (
        status === 400 ||
        status === 401 ||
        status === 500 ||
        status === 404 ||
        status === 403
      ) {
        msg = data.message;
        console.log(msg);
      }

      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const updateReport = createAsyncThunk(
  "reportSlice/updateReport",
  async ({ id, setLoading, body, navigate, navigation }) => {
    try {
      setLoading(true);
      const { data } = await put(
        `${endPoint}/api/v1/report/update?id=${id}`,
        body
      );
      swal("نجاح!", data.msg, "success");
      if (navigation) navigate(-1);
      return data?.data;
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (
        status === 400 ||
        status === 401 ||
        status === 500 ||
        status === 404 ||
        status === 403
      ) {
        msg = data.message;
        console.log(msg);
      }

      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const getGraphCasesByCourts = createAsyncThunk(
  "reportSlice/getGraphCasesByCourts",
  async ({ setGraphCaseLoading }) => {
    try {
      setGraphCaseLoading(true);
      const { data } = await get(
        `${endPoint}/api/v1/report/get_graph_cases_by_courts`
      );
      const labels = data?.data.map((item) => item.courtLabel);
      const counts = data?.data.map((item) => item.count);

      return { labels, counts };
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (
        status === 400 ||
        status === 401 ||
        status === 500 ||
        status === 404 ||
        status === 403
      ) {
        msg = data.message;
        console.log(msg);
      }

      swal("خطأ!", msg, "error");
    } finally {
      setGraphCaseLoading(false);
    }
  }
);

export const getGraphCasesByStatus = createAsyncThunk(
  "reportSlice/getGraphCasesByStatus",
  async ({ setStatusGraphLoading }) => {
    try {
      setStatusGraphLoading(true);
      const { data } = await get(
        `${endPoint}/api/v1/report/get_graph_cases_by_status`
      );
      const labels = data?.data.map((item) => item.label);
      const counts = data?.data.map((item) => item.count);

      return { labels, counts };
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (
        status === 400 ||
        status === 401 ||
        status === 500 ||
        status === 404 ||
        status === 403
      ) {
        msg = data.message;
        console.log(msg);
      }

      swal("خطأ!", msg, "error");
    } finally {
      setStatusGraphLoading(false);
    }
  }
);

export const getGraphCasesByMonths = createAsyncThunk(
  "reportSlice/getGraphCasesByMonths",
  async ({ setMonthsGraphLoading }) => {
    try {
      setMonthsGraphLoading(true);
      const { data } = await get(
        `${endPoint}/api/v1/report/get_graph_cases_by_months`
      );

      const labels = [...new Set(data?.data?.map((item) => item.month))]; // Unique months for x-axis
      const statuses = [
        ...new Set(data?.data?.map((item) => item.caseStatusLabel)),
      ];

      return { data: data.data, labels, statuses };
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (
        status === 400 ||
        status === 401 ||
        status === 500 ||
        status === 404 ||
        status === 403
      ) {
        msg = data.message;
        console.log(msg);
      }

      swal("خطأ!", msg, "error");
    } finally {
      setMonthsGraphLoading(false);
    }
  }
);

export const dashboardStats = createAsyncThunk(
  "reportSlice/dashboardStats",
  async ({ setTotalCases, setActiveCases, setDoneCases }) => {
    try {
      const { data } = await get(`${endPoint}/api/v1/report/dashboard_stats`);
      setTotalCases(data.totalCases);
      setActiveCases(data.activeCases);
      setDoneCases(data.doneCases);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (
        status === 400 ||
        status === 401 ||
        status === 500 ||
        status === 404 ||
        status === 403
      ) {
        msg = data.message;
        console.log(msg);
      }

      swal("خطأ!", msg, "error");
    }
  }
);

export const getCalendarReports = createAsyncThunk(
  "reportSlice/getCalendarReports",
  async ({ setLoading }) => {
    try {
      setLoading(true);
      const { data } = await get(`${endPoint}/api/v1/report/calendar-reports`);
      const formattedEvents = data.map((event) => ({
        title: `Case: ${event.case_number} - ${event.case_reason}`,
        start: new Date(event.date),
        end: new Date(event.date),
      }));
      return formattedEvents;
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (
        status === 400 ||
        status === 401 ||
        status === 500 ||
        status === 404 ||
        status === 403
      ) {
        msg = data.message;
        console.log(msg);
      }
      swal("خطأ!", msg, "error");
    } finally {
      setLoading(false);
    }
  }
);

export const reportSlice = createSlice({
  name: "report",
  initialState: {
    reports: [],
    courtLabels: [],
    courtCounts: [],
    statusLabels: [],
    statusCounts: [],
    monthsLabels: [],
    statuses: [],
    dataByMonths: [],
    topUserLabels: [],
    topUserCounts: [],
    calenderEvents: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getAllReports.fulfilled, (state, action) => {
      state.reports = action.payload;
    });
    builder.addCase(deleteReport.fulfilled, (state, action) => {
      state.reports = state.reports.filter(
        (item) => item._id !== action.payload
      );
    });
    builder.addCase(getGraphCasesByCourts.fulfilled, (state, action) => {
      state.courtLabels = action.payload.labels;
      state.courtCounts = action.payload.counts;
    });
    builder.addCase(getGraphCasesByStatus.fulfilled, (state, action) => {
      state.statusLabels = action.payload.labels;
      state.statusCounts = action.payload.counts;
    });
    builder.addCase(getGraphCasesByMonths.fulfilled, (state, action) => {
      state.dataByMonths = action.payload.data;
      state.monthsLabels = action.payload.labels;
      state.statuses = action.payload.statuses;
    });
    builder.addCase(getCalendarReports.fulfilled, (state, action) => {
      state.calenderEvents = action.payload;
    });
  },
});

export default reportSlice.reducer;
