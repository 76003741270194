// components/DynamicForm.jsx
import { useEffect, useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button, Col, Form, Row } from "react-bootstrap";

const DynamicForm = ({ handleListChange, defaultValue }) => {
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (defaultValue.length) {
      // Create a deep copy of defaultValue to avoid mutating the original array
      const deepCopy = JSON.parse(JSON.stringify(defaultValue));
      setFields(deepCopy);
    } else {
      setFields([{ date: "", description: "" }]);
    }
  }, [defaultValue]);

  const addField = () => {
    setFields([...fields, { date: "", description: "" }]);
  };

  const delField = (index) => {
    let filteredArray = fields.filter((_, i) => i !== index);
    setFields(filteredArray);
    handleListChange(filteredArray);
  };

  const handleTextChange = (index, e) => {
    const { name, value } = e.target;
    const newFields = [...fields];
    newFields[index][name] = value;
    setFields(newFields);
    handleListChange(newFields);
  };

  return (
    <div className="text-end" dir="rtl">
      {fields.map((field, index) => (
        <Row key={index}>
          <Col md={6}>
            <Form.Group controlId="description" className="mb-4">
              <Form.Label>وصف</Form.Label>
              <Form.Control
                name="description"
                value={field.description}
                onChange={(e) => handleTextChange(index, e)}
                rows={3}
                placeholder="أدخل الوصف"
              />
            </Form.Group>
          </Col>
          <Col md={5}>
            <Form.Group controlId="date" className="mb-4">
              <Form.Label>التاريخ</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={field.date}
                onChange={(e) => handleTextChange(index, e)}
              />
            </Form.Group>
          </Col>
          <Col md={1} className="d-flex align-items-end mb-4">
            <Button size="sm" onClick={() => delField(index)} variant="danger">
              <DeleteOutlineOutlinedIcon className=" p-1" />
            </Button>
          </Col>
          <hr />
        </Row>
      ))}
      <Button
        type="button"
        size="sm"
        onClick={addField}
        variant="outline-success"
      >
        <AddOutlinedIcon className=" p-1" />
        <span className="ms-2">أضف آخر</span>
      </Button>
    </div>
  );
};

export default DynamicForm;
