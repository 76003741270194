import { ROLES } from "constants/enums";
import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { ThreeCircles } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { addUser } from "store/slices/userSlice";

const getRoleKey = (value) => {
  return Object.keys(ROLES).find((key) => ROLES[key] === value);
};

const initialFormData = {
  fullName: "",
  email: "",
  password: "",
  role: getRoleKey(ROLES.user),
  phone: "",
};

export default function AddUser({ show, handleClose }) {
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      addUser({
        body: formData,
        setLoading,
        handleClose,
      })
    );
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dir="rtl"
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>إضافة مستخدم</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="fullName" className="mb-4">
              <Form.Label>الاسم الكامل</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                placeholder="أدخل الاسم الكامل"
                required
              />
            </Form.Group>
            <Form.Group controlId="email" className="mb-4">
              <Form.Label>بريد إلكتروني</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="أدخل البريد الإلكتروني"
                required
              />
            </Form.Group>
            <Form.Group controlId="phone" className="mb-4">
              <Form.Label>هاتف</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                dir="rtlg"
                placeholder="أدخل الهاتف"
              />
            </Form.Group>
            <Form.Group controlId="password" className="mb-4">
              <Form.Label>كلمة المرور</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="أدخل كلمة المرور"
                required
              />
            </Form.Group>
            <Form.Group controlId="role" className="mb-4">
              <Form.Label>دور</Form.Label>
              <Form.Select
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                required
              >
                {Object.entries(ROLES).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <ThreeCircles
                  height="50"
                  width="50"
                  color="#0D4cb5"
                  outerCircleColor="#0D4cb5"
                  innerCircleColor="#0D4cb5"
                  barColor="#0D4cb5"
                  ariaLabel="circles-with-bar-loading"
                  visible={true}
                />
              </div>
            ) : (
              <>
                <Button variant="secondary" type="button" onClick={handleClose}>
                  يغلق
                </Button>
                <Button variant="primary" type="submit">
                  يُقدِّم
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
