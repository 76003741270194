import { combineReducers } from "redux";
import userReducer from "./slices/userSlice";
import reportReducer from "./slices/reportSlice";

const rootReducer = combineReducers({
  user: userReducer,
  report: reportReducer,
});

export default rootReducer;
