import React, { useState } from "react";
import { Form, Button, Container, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Signup() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      alert("كلمات المرور غير متطابقة");
      return;
    }

    // Send the form data to the backend
    fetch("http://69.197.176.108/legal-tracking-backend/signup.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "success") {
          alert("تم إنشاء الحساب بنجاح!");
          navigate("/login"); // Redirect to the login page
        } else {
          alert("فشل في إنشاء الحساب: " + data.message);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <Container className="mt-5" dir="rtl">
      <Card className="shadow-sm">
        <Card.Body>
          <h2 className="mb-4 text-center">إنشاء حساب جديد</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-4">
              <Form.Label>الاسم الكامل</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="أدخل اسمك الكامل"
                required
              />
            </Form.Group>

            <Form.Group controlId="email" className="mb-4">
              <Form.Label>البريد الإلكتروني</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="أدخل بريدك الإلكتروني"
                required
              />
            </Form.Group>

            <Form.Group controlId="phone" className="mb-4">
              <Form.Label>رقم الهاتف</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="أدخل رقم هاتفك"
                required
              />
            </Form.Group>

            <Form.Group controlId="password" className="mb-4">
              <Form.Label>كلمة المرور</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="أدخل كلمة المرور"
                required
              />
            </Form.Group>

            <Form.Group controlId="confirmPassword" className="mb-4">
              <Form.Label>تأكيد كلمة المرور</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                placeholder="أدخل تأكيد كلمة المرور"
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              إنشاء الحساب
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Signup;
