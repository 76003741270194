import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

function ProfileDetails() {
  const [profile, setProfile] = useState(null);

  // useEffect(() => {
  //   // Retrieve user ID from localStorage
  //   const userId = localStorage.getItem("user_id");

  //   // Log the user ID for debugging
  //   console.log("User ID from localStorage:", userId);

  //   // Check if the user is logged in
  //   if (!userId) {
  //     alert("يرجى تسجيل الدخول للوصول إلى هذه الصفحة");
  //     window.location.href = "/login"; // Redirect to login page
  //     return;
  //   }

  //   // Fetch the user's profile data from the backend
  //   fetch(
  //     `http://69.197.176.108/legal-tracking-backend/get_profile.php?id=${userId}`
  //   )
  //     .then((response) => {
  //       console.log("Response:", response); // Log the response
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("Fetched Profile Data:", data); // Log the fetched data
  //       if (data && data.name) {
  //         setProfile(data);
  //       } else {
  //         alert("فشل في جلب تفاصيل الملف الشخصي.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching profile:", error);
  //       alert("حدث خطأ أثناء تحميل بيانات الملف الشخصي.");
  //     });
  // }, []);

  // Show loading message until data is fetched
  if (!profile) {
    return <div>تحميل البيانات...</div>;
  }

  return (
    <Container className="mt-5" dir="rtl">
      <Card className="shadow-sm">
        <Card.Body>
          <h2 className="mb-4 text-center">تفاصيل الملف الشخصي</h2>
          <Row>
            <Col md={6}>
              <p>
                <strong>الاسم الكامل:</strong> {profile.name}
              </p>
            </Col>
            <Col md={6}>
              <p>
                <strong>البريد الإلكتروني:</strong> {profile.email}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <p>
                <strong>رقم الهاتف:</strong> {profile.phone}
              </p>
            </Col>
            <Col md={6}>
              <p>
                <strong>تاريخ التسجيل:</strong> {profile.registration_date}
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ProfileDetails;
