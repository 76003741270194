import { ROLES } from "constants/enums";
import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { ThreeCircles } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { shareReport } from "store/slices/reportSlice";
import { addUser } from "store/slices/userSlice";

const initialFormData = {
  email: "",
};

export default function ShareModal({ show, caseId, handleClose }) {
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      shareReport({
        body: { ...formData, caseId },
        setLoading,
        handleClose,
      })
    );
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dir="rtl"
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>حالة حصة</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="email" className="mb-4">
              <Form.Label>بريد إلكتروني</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="أدخل البريد الإلكتروني"
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <ThreeCircles
                  height="50"
                  width="50"
                  color="#0D4cb5"
                  outerCircleColor="#0D4cb5"
                  innerCircleColor="#0D4cb5"
                  barColor="#0D4cb5"
                  ariaLabel="circles-with-bar-loading"
                  visible={true}
                />
              </div>
            ) : (
              <>
                <Button variant="secondary" type="button" onClick={handleClose}>
                  يغلق
                </Button>
                <Button variant="primary" type="submit">
                  يشارك
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
