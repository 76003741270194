/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Table, Container, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CirclesWithBar } from "react-loader-spinner";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import moment from "moment";
import Pagination from "./Pagination";
import { deleteUser, getAllUsers } from "store/slices/userSlice";
import AddUser from "./AddUser";
import EditModel from "./EditUser";
import swal from "sweetalert";
import { ROLES } from "constants/enums";

function Users() {
  const { allUsers, user } = useSelector((state) => state.user);
  const [count, setCount] = useState(0);
  const [role, setRole] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [userData, setUserData] = useState({});

  const dispatch = useDispatch();

  const getUsers = () => {
    dispatch(
      getAllUsers({
        page,
        setCount,
        setLoading,
        role,
        search,
      })
    );
  };

  useEffect(() => {
    getUsers();
  }, [addModel, editModel, page]);

  const handleClose = () => setAddModel(false);
  const handleShow = () => setAddModel(true);

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  const handleDelUser = async (id) => {
    let isSure = await swal({
      title: "هل أنت متأكد؟",
      text: `هل أنت متأكد من إزالة هذا الحساب؟`,
      icon: "warning",
      buttons: ["لا", "نعم"],
      dangerMode: true,
    });
    if (isSure) {
      dispatch(deleteUser({ id, setLoading }));
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <CirclesWithBar
          height="100"
          width="100"
          color="#0D4cb5"
          outerCircleColor="#0D4cb5"
          innerCircleColor="#0D4cb5"
          barColor="#0D4cb5"
          ariaLabel="circles-with-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  if (allUsers?.length === 0 && !loading) {
    return <div>لا يوجد مستخدمين لعرضهم</div>;
  }

  return (
    <Container className="my-5" dir="rtl">
      <h2 className="mb-4 text-center">المستخدمين</h2>
      {ROLES[user.role] === ROLES.super_admin && (
        <Row className="text-start mb-4">
          <Col>
            <Button onClick={handleShow}>إضافة مستخدم</Button>
          </Col>
        </Row>
      )}
      {addModel && <AddUser show={addModel} handleClose={handleClose} />}
      {editModel && (
        <EditModel
          show={editModel}
          userData={userData}
          handleClose={() => {
            setEditModel(false);
            setUserData({});
          }}
        />
      )}
      <div className="row">
        <div className="col">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>معرف المستخدم</th>
                <th>الاسم الكامل</th>
                <th>بريد إلكتروني</th>
                <th>دور</th>
                <th>رقم التليفون</th>
                <th>تاريخ الإنشاء</th>
                {ROLES[user.role] === ROLES.super_admin && <th>الإجراءات</th>}
              </tr>
            </thead>
            <tbody>
              {allUsers?.map((item) => (
                <tr key={item._id}>
                  <td>{item.userID}</td>
                  <td>{item.fullName}</td>
                  <td>{item.email}</td>
                  <td>{ROLES[item.role] || item.role}</td>
                  <td>{item.phone}</td>
                  <td>{moment(item.createdAt).format("DD/MM/YYYY h:mm A")}</td>
                  {ROLES[user.role] === ROLES.super_admin && (
                    <td>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDelUser(item._id)}
                      >
                        <DeleteOutlineOutlinedIcon fontSize="small" />
                      </Button>
                      <Button
                        className="me-1"
                        variant="outline-primary"
                        size="sm"
                        onClick={() => {
                          setUserData(item);
                          setEditModel(true);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      {count > 50 && !loading && (
        <div className="mt-3">
          <Pagination
            count={Math.ceil(count / 50)}
            onPageChange={handlePageClick}
          />
        </div>
      )}
    </Container>
  );
}

export default Users;
