/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Table,
  Container,
  Button,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReport,
  getAllReports,
  updateReport,
} from "store/slices/reportSlice";
import { CirclesWithBar } from "react-loader-spinner";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  caseStatusOptions,
  caseTypeOptions,
  courtOptions,
} from "constants/enums";
import Pagination from "./Pagination";
import moment from "moment";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import ShareModal from "./ShareModal";

function ActiveCases() {
  const { reports } = useSelector((state) => state.report);
  const { user } = useSelector((state) => state.user);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [caseId, setCaseId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getReports = () => {
    dispatch(
      getAllReports({
        page,
        setCount,
        setLoading: setLoading,
        isActiveCases: true,
        search,
      })
    );
  };

  useEffect(() => {
    getReports();
  }, [page]);

  useEffect(() => {
    setTimeout(() => {
      getReports();
    }, 600);
  }, [search]);

  const handleDelReport = async (id, case_number) => {
    let isSure = await swal({
      title: "هل انت متأكد؟",
      text: `هل أنت متأكد من إزالة رقم القضية (${case_number})؟`,
      icon: "warning",
      buttons: ["لا", "نعم"],
      dangerMode: true,
    });
    if (isSure) {
      dispatch(deleteReport({ id, setLoading }));
    }
  };

  const handleDoneReport = async (id, case_number) => {
    let isSure = await swal({
      title: "هل انت متأكد؟",
      text: `هل أنت متأكد من تغيير حالة هذه القضية (${case_number})؟`,
      icon: "warning",
      buttons: ["لا", "نعم"],
      dangerMode: true,
    });
    if (isSure) {
      let body = { case_status: "reserved" };
      dispatch(
        updateReport({ id, setLoading, body, navigate, navigation: false })
      ).then(() => {
        getReports();
      });
    }
  };
  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  const handleClose = () => {
    setShowModal(false);
    setCaseId("");
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <CirclesWithBar
          height="100"
          width="100"
          color="#0D4cb5"
          outerCircleColor="#0D4cb5"
          innerCircleColor="#0D4cb5"
          barColor="#0D4cb5"
          ariaLabel="circles-with-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <Container className="my-5" dir="rtl">
      {showModal && (
        <ShareModal
          show={showModal}
          caseId={caseId}
          handleClose={handleClose}
        />
      )}

      <h2 className="mb-4 text-center">القضايا القضائية النشطة</h2>
      <Row className="text-start mb-4">
        <Col>
          <Button onClick={() => navigate(`/add`)}>إضافة قضية</Button>
        </Col>
      </Row>
      <FormControl
        type="text"
        autoFocus
        placeholder="البحث حسب رقم الحالة أو اسم العميل..."
        className="mb-3"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="row">
        <div className="col">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>رقم القضية</th>
                <th>نوع القضية</th>
                <th>المحكمة</th>
                <th>اسم العميل</th>
                <th>حالة القضية</th>
                <th>سبب القضية</th>
                <th>التاريخ</th>
                <th>الملاحظات</th>
                <th>الإجراءات</th>
              </tr>
            </thead>
            <tbody>
              {reports?.map((caseItem) => (
                <tr key={caseItem._id}>
                  <td>{caseItem.case_number}</td>
                  <td>
                    {caseTypeOptions[caseItem.case_type] || caseItem.case_type}
                  </td>
                  <td>{courtOptions[caseItem.court] || caseItem.court}</td>
                  <td>{caseItem.client_name}</td>
                  <td>
                    {caseStatusOptions[caseItem.case_status] ||
                      caseItem.case_status}
                  </td>
                  <td>{caseItem.case_reason}</td>
                  {caseItem.dates?.slice(0, 1)?.map((item, i) => {
                    return (
                      <td key={i}>{moment(item.date).format("DD/MM/YYYY")}</td>
                    );
                  })}
                  <td>
                    <span className="clamp-3-lines">{caseItem.notes}</span>
                  </td>
                  <td className="align-content-center">
                    <div className="d-flex align-items-center justify-content-center">
                      {user?.role === "super_admin" && (
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() =>
                            handleDelReport(caseItem._id, caseItem.case_number)
                          }
                        >
                          <DeleteOutlineOutlinedIcon fontSize="small" />
                        </Button>
                      )}
                      <Button
                        className="me-1"
                        variant="outline-primary"
                        size="sm"
                        onClick={() => navigate(`/edit/${caseItem._id}`)}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Button>
                      <Button
                        className="me-1"
                        variant="outline-success"
                        size="sm"
                        onClick={() => navigate(`/view/${caseItem._id}`)}
                      >
                        <VisibilityOutlinedIcon fontSize="small" />
                      </Button>
                      <Button
                        className="mx-1"
                        variant="outline-dark"
                        size="sm"
                        onClick={() =>
                          handleDoneReport(caseItem._id, caseItem.case_number)
                        }
                      >
                        <CheckCircleOutlinedIcon fontSize="small" />
                      </Button>
                      <Button
                        variant="outline-warning"
                        size="sm"
                        onClick={() => {
                          setCaseId(caseItem._id);
                          setShowModal(true);
                        }}
                      >
                        <ShareOutlinedIcon fontSize="small" />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      {reports?.length === 0 && !loading && (
        <div className="row">
          <div className="col">
            <div>لا توجد قضايا منتهية لعرضها</div>
          </div>
        </div>
      )}
      {count > 50 && !loading && (
        <div className="mt-3">
          <Pagination
            count={Math.ceil(count / 50)}
            onPageChange={handlePageClick}
          />
        </div>
      )}
    </Container>
  );
}

export default ActiveCases;
