import React, { useState } from "react";
import { Form, Button, Container, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { doLogin } from "store/slices/userSlice";
import { ThreeCircles } from "react-loader-spinner";

const initialFormData = {
  email: "",
  password: "",
};

function Login() {
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    dispatch(doLogin({ body: formData, setLoading, navigate }));
  };

  return (
    <Container className="mt-5" dir="rtl">
      <Card className="shadow-sm">
        <Card.Body>
          <h2 className="mb-4 text-center">تسجيل الدخول</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email" className="mb-4">
              <Form.Label>البريد الإلكتروني</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="أدخل بريدك الإلكتروني"
                required
              />
            </Form.Group>

            <Form.Group controlId="password" className="mb-4">
              <Form.Label>كلمة المرور</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="أدخل كلمة المرور"
                required
              />
            </Form.Group>

            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <ThreeCircles
                  height="50"
                  width="50"
                  color="#0D4cb5"
                  outerCircleColor="#0D4cb5"
                  innerCircleColor="#0D4cb5"
                  barColor="#0D4cb5"
                  ariaLabel="circles-with-bar-loading"
                  visible={true}
                />
              </div>
            ) : (
              <Button variant="primary" type="submit" className="w-100">
                تسجيل الدخول
              </Button>
            )}
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Login;
